.btnShare {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 14px;
  height: 100px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 24px;
}
.btnShareImg {
  margin-bottom: 10px;
}

.img {
  height: 35px;
  width: auto;
  margin-bottom: 10px;
}
.svg {
  height: 25px;
  margin-right: 10px;
}


.close {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  cursor: pointer;
  z-index: 1;
}

.titulo {
  font-size: 28.1px !important;
  font-weight: 800 !important;
  letter-spacing: 0.29px !important;
  line-height: 35px !important;
}

.titulo span {
  font-size: 20px !important;
}

@media (max-width: 767px) {
  .titulo {
    font-size: 25px !important;
  }
}
@media (max-width: 574px) {
  .titulo {
    font-size: 20px !important;
  }

  .close {
    right: 0.5rem;
    top: 0.5rem;
  }
}
