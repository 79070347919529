.listagem_titulo {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.41px;
  line-height: 50px;
  text-align: center;
}
.listagem_subtitulo {
  font-size: 19.69px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
}

.paragrafo {
  font-size: 17px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: justify;
}

.paragrafo a {
  text-decoration: underline;
}
.BannerInt {
  background-position: center 69px;
  background-size: contain;
  background-repeat: no-repeat;
}
.ot-sdk-show-settings{
    color: black !important;
    border: 1px solid #9084FF !important;
    font-size: 17px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 27px;
    height: auto;
    white-space: normal;
    word-wrap: break-word;
    padding: .8em 2em;
    font-size: .8em;
    line-height: 1.2;
    cursor: pointer;
}


@media (max-width: 767px) {
  @media (max-width: 767px) {
    .listagem_titulo {
      font-size: 28px;
      line-height: 35px;
    }
    .listagem_subtitulo {
      font-size: 13.78px;
      line-height: 18px;
    }
  }
}
