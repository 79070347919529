.box {
  height: 23px;
  width: 23px;
  border: 1px solid #e2e2e2;
  border-radius: 11.5px;
  margin-right: 10px;
}
.label {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 20px;
}

.select {
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 0.3px !important;
}

.titulo {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.24px;
  margin-bottom: 15px;
}

.radio {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 20px;
  border: 2px solid #ffffff;
  display: inline-flex;
  padding: 2px;
  border-radius: 30px;
  cursor: pointer;
  width: 100%;
}

.radio.padding {
  padding: 2px 6px;
}

.radio.rigth {
  margin-right: 10px;
}
.radio img {
  margin-right: 10px;
}
.radio:hover {
  border: 2px solid #e2e2e2;
}
.radio.activo {
  border: 2px solid #1fd06c;
}
