.btnDireccoes {
  color: #ffffff;
  border: none;
  border-radius: 22.5px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  height: 45px;
  width: 255px;
  line-height: 45px;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnDireccoes::before {
  border-radius: 22.5px;
  height: 45px;
  width: 255px;
}
.btnDireccoes img {
  margin-right: 10px;
  height: 21px;
  width: 21px;
}

@media (max-width: 574px) {
  .btnDireccoes {
    width: 300px;
  }
  .btnDireccoes::before {
    width: 300px;
  }
}
