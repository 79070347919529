.menu_mobile_stand {
  height: 42px;
  justify-content: center;
}
.menu_mobile_stand img {
  width: auto;
  height: 100%;
}
.close {
  position: absolute;
  left: 16px;
  top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}
.close svg {
  margin-bottom: 10px;
}

.navbarMobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: -5px;
  margin-left: -5px;
  padding: 0;
  list-style: none;
}
.navbarMobile > li {
  width: 33.33333%;
  padding: 5px;
  margin-bottom: 5px;
}
.navbarMobile > li > a,
.navbarMobile > li > div:first-child {
  font-size: 16px;
  position: relative;
  display: block;
  padding: 15px 15px;
  cursor: pointer;
  height: 95px;
}

.navbarMobile > li > a > div:first-child,
.navbarMobile > li > div:first-child > div:first-child {
  height: 100%;
  justify-content: space-between;
}

.navbarMobile > li > a,
.navbarMobile > li > div:first-child {
  border-radius: 15.39px;
  box-shadow: 0 7px 9px 0 rgba(0, 0, 0, 0.03);
}

.navList > li > a {
  padding: 7px 15px;
  position: relative;
  display: block;
}

.menuMobileRedes {
  display: flex;
}

.menuMobileRedesItem {
  display: flex;
  flex-direction: column;
  color: #a8a8a8;
  font-size: 10.14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 13px;
  text-align: center;
  align-items: center;
  text-transform: uppercase;
  height: 100%;
  justify-content: space-between;
}
.menuMobileRedesItem1 {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: #a8a8a8;
  font-size: 10.14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  line-height: 13px;
  text-align: center;
  align-items: center;
  text-transform: uppercase;
  justify-content: space-between;
}

.menuMobileRedesItem img {
  display: block;
  margin: auto;
  max-width: 35px;
  max-height: 100%;
}
.menuMobileTitulo {
  font-size: 18px;
  letter-spacing: 0.28px;
  line-height: 23px;
  margin-bottom: 30px;
}
.menuMobileCopyright {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
}

.menuMobileTexto a {
  color: #a8a8a8;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 25px;
  text-transform: uppercase;
}
.menuMobileTexto {
  margin-top: 10px;
}

.btnEasysite {
  margin-top: 10px;
  font-size: 13.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.btnEasysite svg {
  height: 18px;
  width: 54px;
  margin-right: 10px;
}

.btnEasysite a {
  font-size: 13.2px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 0px;
  text-transform: lowercase;
  color: inherit;
}
.seconddiv {
  width: 100%;
  padding: 0 0;
  left: 0;
  top: 0;
  overflow: hidden;
}
.seconddiv li {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 50px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}
.seconddiv li > a {
  width: 215px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.seconddiv li > a > svg {
  margin-right: 10px;
  height: 20px;
  width: auto;
}

.seconddiv li:last-child {
  border-bottom: none;
}

.opcoesEnter {
  max-height: 0;
}
.opcoesEnterActive {
  transition: max-height 500ms ease-out;

  max-height: 600px;
}
.opcoesExit {
  max-height: 600px;
}
.opcoesExitActive {
  transition: max-height 500ms ease-out;
  max-height: 0;
}
.boxt {
  height: 13px;
  width: 100%;
  background: linear-gradient(180deg, #f8f8f8 0%, #fff 99.59%);
}

.boxBlack {
  height: 13px;
  width: 100%;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

.with {
  width: 15px;
}

.close {
  cursor: pointer;
}

.btn {
  height: 46px;
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  margin-bottom: 10px;
}

.boxMenuBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.55px;
  line-height: 14px;
  cursor: pointer;
  text-align: center;
}

.boxMenuBottom svg {
  height: 31px;
  width: auto;
}
