.quare label {
  position: absolute;
  background-color: #ffffff;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  height: 30px;
  width: 30px;
  justify-content: center;
  border: 2px solid #ee3d22;
  margin-bottom: 0;
  cursor: pointer;
}
.quare.active label {
  background-color: #ee3d22;
  color: #ffffff !important;
}

.quare label[for="danos1"] {
  top: 58px;
  left: 3px;
}
.quare label[for="danos2"] {
  top: 58px;
  left: 290px;
}
.quare label[for="danos3"] {
  top: 104px;
  left: 37px;
}
.quare label[for="danos4"] {
  top: 104px;
  left: 260px;
}
.quare label[for="danos5"] {
  top: 155px;
  left: 37px;
}
.quare label[for="danos6"] {
  top: 155px;
  left: 257px;
}
.quare label[for="danos7"] {
  top: 315px;
  left: 3px;
}
.quare label[for="danos8"] {
  top: 315px;
  left: 290px;
}
.quare label[for="danos9"] {
  top: 340px;
  left: 70px;
}
.quare label[for="danos10"] {
  top: 340px;
  left: 217px;
}
.quare label[for="danos11"] {
  top: 399px;
  left: 145px;
}
.quare label[for="danos12"] {
  top: 60px;
  left: 359px;
}
.quare label[for="danos13"] {
  top: 60px;
  left: 876px;
}

.quare label[for="danos14"] {
  top: 126px;
  left: 427px;
}
.quare label[for="danos15"] {
  top: 126px;
  left: 802px;
}
.quare label[for="danos16"] {
  top: 207px;
  left: 407px;
}
.quare label[for="danos17"] {
  top: 207px;
  left: 829px;
}
.quare label[for="danos18"] {
  top: 300px;
  left: 411px;
}
.quare label[for="danos19"] {
  top: 300px;
  left: 821px;
}

.quare label[for="danos20"] {
  top: 360px;
  left: 359px;
}
.quare label[for="danos21"] {
  top: 361px;
  left: 875px;
}
.quare label[for="danos22"] {
  top: 458px;
  left: 418px;
}
.quare label[for="danos23"] {
  top: 458px;
  left: 815px;
}
.quare label[for="danos24"] {
  top: 50px;
  left: 615px;
}
.quare label[for="danos25"] {
  top: 157px;
  left: 615px;
}
.quare label[for="danos26"] {
  top: 287px;
  left: 615px;
}
.quare label[for="danos27"] {
  top: 472px;
  left: 615px;
}

.img {
  width: 906px;
  display: flex;
  flex-wrap: wrap;
}
.img img:nth-child(1) {
  width: 100%;
}
.img img:nth-child(2) {
  width: 100%;
  display: none;
}
.img img:nth-child(3) {
  width: 100%;
  display: none;
}
.img img:nth-child(4) {
  width: 100%;
  display: none;
}
.img img:nth-child(5) {
  width: 100%;
  display: none;
}

.flex {
  position: relative;
}
@media only screen and (max-width: 1199px) {
  .img {
    width: 100%;
  }
  .quare label[for="danos2"] {
    left: 277px;
  }
  .quare label[for="danos4"] {
    left: 247px;
  }
  .quare label[for="danos6"] {
    left: 244px;
  }
  .quare label[for="danos8"] {
    left: 277px;
  }
  .quare label[for="danos10"] {
    left: 217px;
  }
  .quare label[for="danos11"] {
    left: 140px;
  }
  .quare label[for="danos12"] {
    left: 342px;
  }
  .quare label[for="danos13"] {
    left: 845px;
  }
  .quare label[for="danos14"] {
    top: 119px;
    left: 410px;
  }
  .quare label[for="danos15"] {
    top: 110px;
    left: 770px;
  }
  .quare label[for="danos16"] {
    left: 390px;
  }
  .quare label[for="danos17"] {
    top: 200px;
    left: 795px;
  }
  .quare label[for="danos18"] {
    top: 295px;
    left: 399px;
  }
  .quare label[for="danos19"] {
    left: 795px;
  }

  .quare label[for="danos20"] {
    left: 342px;
  }
  .quare label[for="danos21"] {
    left: 850px;
  }
  .quare label[for="danos22"] {
    top: 450px;
    left: 399px;
  }
  .quare label[for="danos23"] {
    top: 450px;
    left: 795px;
  }
  .quare label[for="danos24"] {
    left: 595px;
  }
  .quare label[for="danos25"] {
    left: 595px;
  }
  .quare label[for="danos26"] {
    left: 595px;
  }
  .quare label[for="danos27"] {
    top: 455px;
    left: 595px;
  }
}

@media only screen and (max-width: 991px) {
  .quare label[for="danos1"] {
    top: 36px;
    left: 0px;
  }
  .quare label[for="danos2"] {
    top: 35px;
    left: 198px;
  }
  .quare label[for="danos3"] {
    top: 74px;
    left: 25px;
  }
  .quare label[for="danos4"] {
    top: 74px;
    left: 175px;
  }
  .quare label[for="danos5"] {
    top: 117px;
    left: 19px;
  }
  .quare label[for="danos6"] {
    top: 117px;
    left: 176px;
  }
  .quare label[for="danos7"] {
    top: 212px;
    left: 0px;
  }
  .quare label[for="danos8"] {
    top: 212px;
    left: 200px;
  }
  .quare label[for="danos9"] {
    top: 235px;
    left: 40px;
  }
  .quare label[for="danos10"] {
    top: 235px;
    left: 155px;
  }
  .quare label[for="danos11"] {
    top: 284px;
    left: 99px;
  }
  .quare label[for="danos12"] {
    top: 37px;
    left: 243px;
  }
  .quare label[for="danos13"] {
    top: 35px;
    left: 600px;
  }
  .quare label[for="danos14"] {
    top: 80px;
    left: 294px;
  }
  .quare label[for="danos15"] {
    top: 82px;
    left: 559px;
  }
  .quare label[for="danos16"] {
    top: 140px;
    left: 279px;
  }

  .quare label[for="danos17"] {
    top: 140px;
    left: 573px;
  }
  .quare label[for="danos18"] {
    top: 211px;
    left: 279px;
  }
  .quare label[for="danos19"] {
    top: 206px;
    left: 573px;
  }
  .quare label[for="danos20"] {
    top: 254px;
    left: 243px;
  }
  .quare label[for="danos21"] {
    top: 253px;
    left: 610px;
  }
  .quare label[for="danos22"] {
    top: 318px;
    left: 279px;
  }
  .quare label[for="danos23"] {
    top: 318px;
    left: 573px;
  }
  .quare label[for="danos24"] {
    top: 14px;
    left: 563px;
  }
  .quare label[for="danos25"] {
    top: 40px;
    left: 428px;
  }
  .quare label[for="danos26"] {
    top: 190px;
    left: 428px;
  }
  .quare label[for="danos27"] {
    top: 324px;
    left: 428px;
  }
}

@media only screen and (max-width: 767px) {
  .img img:nth-child(1) {
    display: none;
  }
  .img img:nth-child(2) {
    display: block;
  }
  .img img:nth-child(3) {
    width: 50%;
    display: block;
  }
  .img img:nth-child(4) {
    width: 50%;
    display: block;
  }
  .img img:nth-child(5) {
    display: block;
  }

  .quare label[for="danos1"] {
    top: 58px;
    left: 12px;
  }
  .quare label[for="danos2"] {
    top: 58px;
    left: 411px;
  }
  .quare label[for="danos3"] {
    top: 130px;
    left: 53px;
  }
  .quare label[for="danos4"] {
    top: 130px;
    left: 370px;
  }
  .quare label[for="danos5"] {
    top: 204px;
    left: 53px;
  }
  .quare label[for="danos6"] {
    top: 204px;
    left: 370px;
  }
  .quare label[for="danos7"] {
    top: 420px;
    left: 9px;
  }
  .quare label[for="danos8"] {
    top: 420px;
    left: 412px;
  }
  .quare label[for="danos9"] {
    top: 445px;
    left: 70px;
  }
  .quare label[for="danos10"] {
    top: 445px;
    left: 355px;
  }
  .quare label[for="danos11"] {
    top: 555px;
    left: 208px;
  }
  .quare label[for="danos12"] {
    top: 763px;
    left: 9px;
  }
  .quare label[for="danos13"] {
    top: 763px;
    left: 412px;
  }

  .quare label[for="danos14"] {
    top: 857px;
    left: 103px;
  }
  .quare label[for="danos15"] {
    top: 857px;
    left: 320px;
  }
  .quare label[for="danos16"] {
    top: 965px;
    left: 79px;
  }
  .quare label[for="danos17"] {
    top: 965px;
    left: 345px;
  }
  .quare label[for="danos18"] {
    top: 1106px;
    left: 79px;
  }
  .quare label[for="danos19"] {
    top: 1106px;
    left: 345px;
  }

  .quare label[for="danos20"] {
    top: 1200px;
    left: 9px;
  }
  .quare label[for="danos21"] {
    top: 1200px;
    left: 412px;
  }
  .quare label[for="danos22"] {
    top: 1305px;
    left: 99px;
  }
  .quare label[for="danos23"] {
    top: 1305px;
    left: 319px;
  }
  .quare label[for="danos24"] {
    top: 1561px;
    left: 213px;
  }
  .quare label[for="danos25"] {
    top: 1761px;
    left: 213px;
  }
  .quare label[for="danos26"] {
    top: 1961px;
    left: 213px;
  }
  .quare label[for="danos27"] {
    top: 2346px;
    left: 213px;
  }
}
@media only screen and (max-width: 576px) {
  .quare label[for="danos1"] {
    top: 2.5%;
    left: 3%;
  }
  .quare label[for="danos2"] {
    top: 2.5%;
    left: 91%;
  }
  .quare label[for="danos3"] {
    top: 5.3%;
    left: 14%;
  }
  .quare label[for="danos4"] {
    top: 5.3%;
    left: 80%;
  }
  .quare label[for="danos5"] {
    top: 8.3%;
    left: 15%;
  }
  .quare label[for="danos6"] {
    top: 8.3%;
    left: 79%;
  }
  .quare label[for="danos7"] {
    top: 17.3%;
    left: 3%;
  }
  .quare label[for="danos8"] {
    top: 17.3%;
    left: 91%;
  }
  .quare label[for="danos9"] {
    top: 18.4%;
    left: 16%;
  }
  .quare label[for="danos10"] {
    top: 18.4%;
    left: 79%;
  }
  .quare label[for="danos11"] {
    top: 22%;
    left: 47%;
  }
  .quare label[for="danos12"] {
    top: 31%;
    left: 1%;
  }
  .quare label[for="danos13"] {
    top: 31%;
    left: 93%;
  }

  .quare label[for="danos14"] {
    top: 35%;
    left: 23%;
  }
  .quare label[for="danos15"] {
    top: 35%;
    left: 70%;
  }
  .quare label[for="danos16"] {
    top: 40%;
    left: 18%;
  }
  .quare label[for="danos17"] {
    top: 40%;
    left: 76%;
  }
  .quare label[for="danos18"] {
    top: 46%;
    left: 18%;
  }
  .quare label[for="danos19"] {
    top: 46%;
    left: 76%;
  }

  .quare label[for="danos20"] {
    top: 49%;
    left: 0vw;
  }
  .quare label[for="danos21"] {
    top: 49%;
    left: 94%;
  }
  .quare label[for="danos22"] {
    top: 54%;
    left: 23%;
  }
  .quare label[for="danos23"] {
    top: 54%;
    left: 70%;
  }
  .quare label[for="danos24"] {
    top: 63%;
    left: 47%;
  }
  .quare label[for="danos25"] {
    top: 73%;
    left: 47%;
  }
  .quare label[for="danos26"] {
    top: 83%;
    left: 47%;
  }
  .quare label[for="danos27"] {
    top: 97%;
    left: 47%;
  }
}
