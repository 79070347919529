.titulo {
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
}
.texto {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
  min-height: 170px;
}
.data {
  color: #a8a8a8;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: right;
}
.Localidade {
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}
.box {
  padding: 30px;
  border-radius: 38px !important;
  margin-bottom: 30px;
}
.btn {
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  height: 45px;
  outline: 0;
  border: none;
  border-radius: 22.5px;
  color: #ffffff;
  position: relative;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 15px;
  display: flex;
  width: 265px;
  cursor: pointer;
}
.verMais {
  cursor: pointer;
  display: inline-block;
  margin-left: 5px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
  text-transform: uppercase;
}
.Op > div:first-child {
  width: 45px;
  float: left;
}

@media (max-width: 1399px) {
  .data {
    text-align: left;
    margin-bottom: 15px;
  }
}
