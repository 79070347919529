.label {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 20px;
  display: inline-block;
  width: 150px;
}
.rectangule {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box:nth-child(3n + 1) .rectangule {
  display: block;
  align-items: inherit;
  justify-content: initial;
  padding-left: 15px;
}

.box {
  border-right: 1px solid #e2e2e2;
  padding-bottom: 1rem;
}

.box:nth-child(3n) {
  border-right: 0px solid #e2e2e2;
}
.box:nth-last-child(-n + 3) {
  padding-bottom: 0rem;
}

@media only screen and (max-width: 1399px) {
  .box:nth-child(3n + 1) .rectangule {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .box {
    border-right: 1px solid #e2e2e2;
    padding-bottom: 1rem;
  }
  .box:nth-child(3n) {
    border-right: 1px solid #e2e2e2;
  }
  .box:nth-child(2n) {
    border-right: 0px solid #e2e2e2;
  }
  .box:nth-last-child(-n + 2) {
    padding-bottom: 0rem;
  }
  .box .rectangule {
    display: block;
    align-items: inherit;
    justify-content: initial;
  }
  .box:nth-child(3n + 1) .rectangule {
    display: block;
    align-items: inherit;
    justify-content: initial;
  }
  .box:nth-child(3n + 1) .rectangule {
    padding-left: 0px;
  }
  .box:nth-child(2n + 1) .rectangule {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 1199px) {
  .label {
    width: 134px;
  }
}

@media only screen and (max-width: 767px) {
  .box .rectangule {
    padding-left: 15px;
  }
  .box:nth-child(3n + 1) .rectangule {
    padding-left: 15px;
  }

  .box:nth-last-child(-n + 3) {
    padding-bottom: 1rem;
  }
  .box:nth-last-child(1) {
    padding-bottom: 0rem;
  }
  .box {
    border-right: 0px solid #e2e2e2;
  }
  .box:nth-child(3n) {
    border-right: 0px solid #e2e2e2;
  }
}

@media only screen and (max-width: 375px) {
  .box .rectangule {
    padding-left: 0px;
  }
  .box:nth-child(3n + 1) .rectangule {
    padding-left: 0px;
  }
  .box:nth-child(2n + 1) .rectangule {
    padding-left: 0px;
  }
  .label {
    width: 98px;
  }
}
