.box {
  height: 23px;
  width: 23px;
  border: 1px solid #e2e2e2;
  border-radius: 11.5px;
  margin-right: 10px;
}
.label {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 20px;
}
.radio {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 20px;
  border: 2px solid #ffffff;
  display: inline-flex;
  padding: 2px;
  border-radius: 30px;
  cursor: pointer;
}

.radio.padding {
  padding: 2px 6px;
}

.radio.rigth {
  margin-left: 10px;
}
.radio img {
  margin-left: 10px;
}
.radio:hover {
  border: 2px solid #e2e2e2;
}
.radio.activo {
  border: 2px solid #1fd06c;
}

.labelFather {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 20px;
  display: inline-block;
  width: 150px;
}
.rectanguleFather {
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxFather:nth-child(1) .rectanguleFather {
  display: block;
  align-items: initial;
  justify-content: initial;
  padding-left: 15px;
  border-right: 1px solid #e2e2e2;
}
@media only screen and (max-width: 991px) {
  .boxFather:nth-child(1) .rectanguleFather {
    display: flex;
    align-items: center;
    border-right: 0px solid #e2e2e2;
  }
  .boxFather:nth-child(1) {
    padding-bottom: 1rem;
  }

  .rectanguleFather {
    padding-left: 15px;
    justify-content: initial;
  }
}

@media only screen and (max-width: 375px) {
  .boxFather:nth-child(1) .rectanguleFather {
    padding-left: 0;
  }
  .rectanguleFather {
    padding-left: 0;
  }
  .boxFather:nth-child(1) .rectanguleFather {
    display: block;
    align-items: initial;
  }
  .boxFather .rectanguleFather {
    display: block;
    align-items: initial;
  }
  .labelFather {
    width: 100%;
  }
  .radio.rigth {
    margin-left: 5px;
  }
}
