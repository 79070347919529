.boxShadowPares {
  min-height: 46px;
  position: relative;
  border-radius: 26px;
  padding: 15px 15px;
}
.boxShadowPares > label {
  position: absolute;
  top: -9px;
  left: 20px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 1;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.24px;
  line-height: 14px;
  display: flex;
  align-items: center;
}

.boxShadowPares > label > span {
  height: 15px;
  background-color: #000;
  margin-left: 10px;
  color: #fff;
  border-radius: 50%;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.boxShadowPares label img {
  margin-right: 10px;
}
.rectangle {
  height: 108px;
  display: flex;
  align-items: center;
  margin-top: -10px;
}
.btnMore {
  color: #ffffff;
  border: none;
  border-radius: 27px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
  height: 54px;
  width: 386px;
  line-height: 45px;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}
.padding {
  padding: 0 50px;
}
.btnMore::before {
  border-radius: 27px;
  height: 54px;
  width: 386px;
}

@media only screen and (max-width: 1199px) {
  .padding {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .boxShadowParesRange {
    border-radius: 22.5px;
  }
}

@media only screen and (max-width: 576px) {
  .padding {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  .noPaddingxs {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .btnMore {
    width: 100%;
  }
  .btnMore::before {
    width: 100%;
  }
}
