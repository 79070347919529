.box {
  height: 23px;
  width: 23px;
  border: 1px solid #e2e2e2;
  border-radius: 11.5px;
  margin-right: 10px;
}
.label {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 20px;
}
.radio {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 20px;
  border: 2px solid #ffffff;
  display: inline-flex;
  padding: 2px;
  border-radius: 30px;
  cursor: pointer;
}

.radio.padding {
  padding: 2px 6px;
}

.radio.rigth {
  margin-left: 10px;
}
.radio img {
  margin-left: 10px;
}
.radio:hover {
  border: 2px solid #e2e2e2;
}
.radio.activo {
  border: 2px solid #1fd06c;
}
.btn {
  height: 40px;
  border-radius: 22.5px;
  color: #ffffff;
  border: none;
  text-transform: uppercase;

  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 30px;
}
